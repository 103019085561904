import { put, takeLatest, all, select } from 'redux-saga/effects'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import * as rawActions from '../actions'
import { signalrEvents, signalrConstants } from '../../signalr'

function* onShowInfoMessage(action) {
  const [{ infoMessage, userId }] = action.payload
  const currentUserId = yield select(getCurrentUserId)

  if (currentUserId === userId) {
    yield put(rawActions.openInfoMessageDialog(infoMessage))
  }
}

function* formActions() {
  yield all([takeLatest(signalrEvents[signalrConstants.tabeebHubName].onShowInfoMessage, onShowInfoMessage)])
}

export default formActions
